<template>
  <div>
    <navigation />
    <div class="flix-container">
      <h1 class="flix-form-group flix-html-h1 flix-text-danger"><flixIcon id="warning-sign" /> {{ $tc('validationError.header', 1) }}</h1>
      <div v-html="$nl2br($t('validationError.message'))"></div>
    </div>
    <navigationFooter />
  </div>
</template>
<script>
export default {
  components: {
    navigation () { return import('@/components/frontend/navigation/navigation') },
    navigationFooter () { return import('@/components/frontend/navigation/navigationFooter') }
  },
  props: {
    data: Object
  },
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
